@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  line-height: 1.5;
  font-family: F1Reg, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 200;
}

button {
  font-family: "F1Med";
}
/* body {
  color: #00095b;
}

a {
  color: #00095b;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "F1Bold";
}
*/

@font-face {
  font-family: "F1Bold";
  src: local("F1Bold"), url("Fonts/F1/FordF-1-Bold.eot") format("eot"),
    url("Fonts/F1/FordF-1-Bold.ttf") format("ttf"),
    url("Fonts/F1/FordF-1-Bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "F1Med";
  src: local("F1Med"), url("Fonts/F1/FordF-1-Medium.eot") format("eot"),
    url("Fonts/F1/FordF-1-Medium.ttf") format("ttf"),
    url("Fonts/F1/FordF-1-Medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "F1Reg";
  src: local("F1Reg"), url("Fonts/F1/FordF-1-Regular.eot") format("eot"),
    url("Fonts/F1/FordF-1-Regular.ttf") format("ttf"),
    url("Fonts/F1/FordF-1-Regular.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "AntennaBold";
  src: local("AntennaBold"),
    url("Fonts/Antenna/FordAntenna-Bold.eot") format("eot"),
    url("Fonts/Antenna/FordAntenna-Bold.ttf") format("ttf"),
    url("Fonts/Antenna/FordAntenna-Bold.woff") format("woff");
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}
